import React from 'react';
import { Router, Redirect } from '@reach/router';
import PropTypes from 'prop-types';
import Layout from '../../layout';
import ProductList from '../../components/productList';
import './style.css';

const Products = props => {
  return (
    <Router className="router-container">
      <Redirect from="/search" to="/" />
      <ProductList path="/search/:result" />
    </Router>
  );
};

Products.propTypes = {
  location: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Products;
